import React from 'react'
import { Container, Row,Col ,Card} from 'react-bootstrap'
import Footer from './Footer'

function Projects() {


  const projectdata=[
    {image:"https://www.technoelevators.com/images/services-1.png" ,heading:"Creating Gearless Elevators",content:"Techno Industries introduced this technology about 7 year ago and have installed it at around forty locations all over in Gujarat. All of them have been running successfully. The reason we suggest to change is because gearless elevators are more energy efficient. The reason behind that is geared motors consists of two main components namely, the motor and gear box."},
    {image:"https://www.technoelevators.com/images/services-2.png" ,heading:"Creating Modernised Escalators",content:"Escalators are widely used in big malls, supermarkets, subways, railway stations, airports, business centers, and other public places. It is an ideal transportation means connecting different floors and destination points of the building. We can provide wide range of escalators and the moving walk, which at present are the market's potential need."},

    {image:"https://www.technoelevators.com/images/services-3.png" ,heading:"Provided Industrial Elevators",content:"Freight elevator (or goods lift) is an elevator designed to carry goods, rather than passengers. Freight elevators are typically larger and capable of carrying heavier loads than a passenger elevator. Industrial Elevator is a perfect solution for the installations where bulk commodities/goods are needed to be transported vertically like work-shops, show-room, industries etc."},

  ]


  
  return (
    <div>
        
        <Container fluid>
            <Row style={{display:'flex',justifyContent:"center"}} >
                <div className='text-center mt-5 mb-3  section-title'>
                  
                  
                <p cl> Our Projects</p> 
                  </div>
                <Col lg={10} style={{display:'flex',justifyContent:"center"}} >
                <p className='text-center' style={{width:'700px'}}>
 Our Projects

At Techno Elevator Company, we take pride in our diverse portfolio of projects that showcase our commitment to excellence in elevator solutions</p>
</Col>   
     </Row>

     <Row  style={{display:'flex',justifyContent:"center"}} className='mt-3 mb-5' >
  <Col lg={11}>
    <Row>
{projectdata.map((item)=>{
    return(
<Col lg={4} >
 
      <Card>
    <img src={item.image} />
    <div className='project-psge-card-div'>
    <h5>{item.heading}</h5>
    <p>{item.content}</p>
    </div>
  </Card>


  
</Col>
    )})} 
    </Row>
    </Col>
    </Row>
            
        </Container>
        <Footer/>
    </div>
  )
}

export default Projects
