import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MyCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animateCaption, setAnimateCaption] = useState(false);
  const captionRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Set autoplay speed to 5 seconds
    
  };


  
 

  return (
    <div className="carousel-container">
      <Slider {...settings} >
        <div className="slide-item">
          <img src='/MicrosoftTeams-image (47).png' width={'100%'} />
          <div ref={captionRef} className="caption"><h3>Elevates <br /> your project</h3></div>
        </div>
        <div className="slide-item">
          <img src='/MicrosoftTeams-image (49).png' width={'100%'} />
          <div ref={captionRef} className="caption"><h3 style={{ color: 'white' }}>Step on <br />For  Rise</h3></div>
        </div>
      </Slider>
    </div>
  );
};

export default MyCarousel;
