import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Homepages/Footer';
import { Accordion } from 'react-bootstrap';

const AboutUs = () => {
  return (
    <>


      <section className="about-page spad">
        <Container>
          <Row>
            <div className='text-center mb-5 mt-5 section-title'>


              <p className='about-heading' >About Us</p>
            </div>

            <Col lg={12} md={8}>
            <Row className=' mb-5'>
              <Col lg={6}>
              <div className="about__page__services__text">
                  <h5>Welcome to Techno Elevators LLC</h5>
                  <p>Techno Elevators, a fully owned subsidiary of Bahwan International Group, has been operating in Oman since 2007. We specialize in the installation and maintenance of elevators, escalators, and travelators, delivering customized solutions for a wide range of industries.</p>
                  <p>At Techno Elevators LLC, we are proud to serve as the exclusive distributor for Hyundai elevators with exclusive rights for sales, installation and service for Hyundai elevator products in Oman. With a strong commitment to excellence and innovation, we provide state-of-the-art solutions that meet the highest standards of safety, efficiency, and reliability.</p>
                  <p style={{marginBottom:"0px"}}>With 1,000+ elevators successfully installed and maintained across Oman, we take pride in each project that has contributed to the growth of Oman's commercial and residential real estate. Every project reflects our dedication to quality and customer satisfaction. We continue to set benchmarks in the industry, offering reliable solutions that cater to diverse needs across Oman.</p>

                </div>
              </Col>
              <Col lg={6}>
              <img src='/Images/sliders/view-escalator-underground-station_1359-538.jpg' width={'100%'} style={{objectFit:"cover",height:"100%"}} />
              </Col>
            </Row>
              <div className="about__page__services">
             
                <Row className='mt-5'>
                  <Col lg={4} md={6} sm={6}>
                    <div className="services__item">
                      <img className='mb-4' src="/Asests/MicrosoftTeams-image (53).png" alt="" width={'70px'} />
                      <h4 className='mb-4 '>Our Vision</h4>
                      <p className='mb-3'>To be the leading provider of innovative and reliable elevator and escalator solutions in Oman, enhancing the quality of life and setting new standards in vertical and horizontal transportation.</p>
                      <p>At Techno Elevators LLC, we envision a future where our advanced Vertical and Horizontal Transportation solutions are integral to the development of modern, sustainable, and accessible buildings across Oman. We aim to be recognized for our commitment to excellence, innovation, and customer satisfaction.</p>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <div className="services__item">
                      <img className='mb-4' src="/Asests/MicrosoftTeams-image (54).png" alt="" width={'60px'} />
                      <h4 className='mb-4'>Our Mission</h4>
                      <p className='mb-3'>To deliver state-of-the-art Hyundai elevator solutions that prioritize safety, efficiency, and customer satisfaction, while fostering a culture of continuous improvement and environmental responsibility.</p>
                      <p>We strive to enhance the quality of life by providing safe, efficient, and innovative elevator solutions. We strive to exceed customer expectations through our dedication to quality, customer service, and continuous improvement.</p>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={6} >
                    <div className="services__item">
                      <img className='mb-4' src="/Asests/MicrosoftTeams-image (55).png" alt="" width={'70px'} />
                      <h4 className='mb-4'>Our Values</h4>
                      <p className='mb-2'><b>Integrity:</b> We conduct our business with the highest ethical standards, ensuring transparency and honesty in all our dealings.</p>
                      <p className='mb-2'><b>Quality:</b> We are committed to delivering products and services of the highest quality, ensuring customer satisfaction and long-term reliability.</p>
                      <p className='mb-2'><b>Innovation:</b> We embrace innovation and continuously seek new ways to improve our products and services.</p>
                      <p className='mb-2'><b>Customer Focus:</b> We prioritize our customers’ needs and work diligently to provide solutions that meet and exceed their expectations.</p>
                      <p className='mb-2'><b>Teamwork:</b> We believe in the power of collaboration and work together to achieve our common goals.
                        Integrity, Ownership (partnership – customer satisfaction and Hyundai), passion (Teamwork & customer focus) and excellence (quality & innovation)
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} className='mb-5' >
                  <Row  style={{display:'flex',justifyContent:'center'}}>
                    <Col lg={9}>
                    <div>
                      <h3 className='text-center mt-5 mb-5'>Why Choose Us?</h3>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <strong>Expertise and Experience</strong>
                          </Accordion.Header>
                          <Accordion.Body>
                            With 17 years of experience in the local elevator industry, our team of professionals is equipped with the knowledge and skills to deliver exceptional service.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <strong>Quality Products</strong>
                          </Accordion.Header>
                          <Accordion.Body>
                            We offer a comprehensive range of Hyundai elevators, known for their advanced technology, durability, and performance.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <strong>Customer-Centric Approach</strong>
                          </Accordion.Header>
                          <Accordion.Body>
                            Our customers are at the heart of everything we do. We work closely with our clients to understand their needs and provide customized solutions.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <strong>Comprehensive Services</strong>
                          </Accordion.Header>
                          <Accordion.Body>
                            From installation to maintenance, we provide a full spectrum of services to ensure the longevity and optimal performance of your elevators.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                    </div>

                    </Col>
                  </Row>
                   
                  </Col>

                </Row>
                <div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="callto spad set-bg" style={{backgroundImage: 'url(	https://themewagon.github.io/staging/img/call-bg.jpg)' }}>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg={10} className="text-center">
            <div className="callto__text">
              <span>Why choose us?</span>
              <h2>Our ability to bring outstanding results to our customers.</h2>
              <a href="contact" className="primary-btn">Contact Us</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section> */}


      {/* <section className="team spad mt-5 mb-5">
      <Container>
        <Row>
          <Col lg={9} md={8} sm={6}>
            
          </Col> 
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img02.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Products</h5>
                  <span>Elevators</span>
                </div>
                <p>Hyundai Elevator develops products appropriate for building designs and elevator uses so as to provide customized solutions.
Not only safety and ride quality but space efficiency is taken into account to provide you with
optimal products that will raise the value of buildings with exquisite designs and convenient functions.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img03.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Service</h5>
                  <span>Elevators</span>
                </div>
                <p>Elevators and escalators need regular inspections and maintenance to run under optimum conditions.
Hyundai Elevator operates an advanced remote maintenance system (HRTS) built on Korea's IoT convergence technology.
We will do the best we can to ensure prompt response, excellent maintenance and differentiated service quality.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img04.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Modernization</h5>
                  <span>Elevators</span>
                </div>
                <p>Ageing elevators need replacement as well as design upgrades.
Hyundai Elevator has the systematic expertise and extensive know-how to offer
reasonable modernization solutions, which will lead to reduced
construction costs and duration.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section> */}
      <Footer />

    </>
  );
};

export default AboutUs;
