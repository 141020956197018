import React, { useEffect, useRef, useState } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import { Link, useLocation } from 'react-router-dom';
import Footer from './Homepages/Footer';
import AOS from 'aos';
import { Accordion } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';



const keypoints = [
    {
        "heading": "Smooth Ride Quality",
        "text": "Advanced control systems and precision engineering ensure a smooth and comfortable ride."
    },
    {
        "heading": "Spacious Cabins",
        "text": "Our elevators are designed with spacious cabins to accommodate more passengers comfortably."
    },
    {
        "heading": "User-Friendly Interface",
        "text": "Easy-to-use control panels and intuitive interfaces make our elevators accessible to all users."
    },
    {
        "heading": "Quiet Operation",
        "text": "Noise reduction technology ensures a quiet and peaceful ride, enhancing the comfort of passengers.",
        "classname": "borderbotam"

    }
]

const elevatorBenefits = [
    {
        heading: "Advanced Technology",
        text: "Hyundai passenger elevators are equipped with cutting-edge technology that ensures smooth and quiet operation, enhancing the overall user experience."
    },
    {
        heading: "Safety First",
        text: "Our elevators are designed with multiple safety features, including emergency braking systems, overload sensors, and automatic rescue devices, to ensure the safety of all passengers."
    },
    {
        heading: "Energy Efficiency",
        text: "Hyundai elevators are built with energy-efficient components that reduce power consumption and lower operational costs, contributing to a greener environment."
    },
    {
        heading: "Customizable Designs",
        text: "We offer a variety of design options to match the aesthetic of your building. From sleek and modern to classic and elegant, our elevators can be customized to fit your style."
    },
    {
        heading: "Durability and Reliability",
        text: "Built with high-quality materials and robust engineering, Hyundai passenger elevators are known for their durability and long-lasting performance.",
        classname: "borderbotam"
    }
];


const elevatorApplications = [
    {
        heading: "Residential Buildings",
        text: "Enhance the convenience and accessibility of your home with our stylish and efficient passenger elevators."
    },
    {
        heading: "Commercial Buildings",
        text: "Improve the flow of people in office buildings, shopping malls, and hotels with our reliable and high-capacity elevators."
    },
    {
        heading: "Public Spaces",
        text: "Ensure smooth and efficient transportation in public buildings such as hospitals, airports, and train stations."
    }
];

const homeElevatorFeatures = [
    {
        heading: "Stylish Design",
        text: "Our home elevators are available in a variety of styles and finishes to complement your home’s interior. Whether you prefer a modern look or a classic design, we have options to suit your taste."
    },
    {
        heading: "Compact and Space-Efficient",
        text: "Designed to fit seamlessly into your home, our elevators require minimal space and can be installed in new or existing buildings without major modifications."
    },
    {
        heading: "Quiet and Smooth Operation",
        text: "Hyundai home elevators are engineered for quiet and smooth operation, ensuring a comfortable ride without disturbing the peace of your home."
    },
    {
        heading: "Safety Features",
        text: "Equipped with advanced safety features such as emergency stop buttons, battery backup, and door sensors, our elevators provide peace of mind for you and your family."
    },
    {
        heading: "Energy Efficiency",
        text: "Our elevators are designed to be energy-efficient, reducing power consumption and contributing to a greener environment.",
        classname: "borderbotam"

    }
];

const keyFeatures1 = [
    {
        heading: "Customizable Cabins",
        text: "Choose from a range of cabin finishes, lighting options, and control panels to create an elevator that perfectly matches your home’s decor."
    },
    {
        heading: "User-Friendly Controls",
        text: "Easy-to-use control panels and intuitive interfaces make our elevators accessible to all family members."
    },
    {
        heading: "Smooth Ride Quality",
        text: "Advanced control systems ensure a smooth and comfortable ride every time."
    },
    {
        heading: "Durability and Reliability",
        text: "Built with high-quality materials and robust engineering, Hyundai home elevators are designed for long-lasting performance.", classname: "borderbotam"

    }
];

const applications = [
    {
        heading: "Private Residences",
        text: "Enhance the accessibility and luxury of your home with a stylish and efficient elevator."
    },
    {
        heading: "Multi-Story Homes",
        text: "Make moving between floors effortless and convenient, especially for elderly family members or those with mobility challenges."
    },
    {
        heading: "Luxury Apartments",
        text: "Add a touch of sophistication and convenience to your apartment with a compact and elegant home elevator."
    }
];



function Products() {

    const [height, setHeight] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    const elementRef = useRef(null);

    const products = [
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Capsule Elevators", link: "capsule-elevators" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/40b9c124798881.5633a41426cfa.jpg", title: "Elevator visualization.", link: "passenger-elevators-autodoor" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: " Elevator interior design", link: "escalators-interior" },

    ]


    const products2 = [
        { image: "https://img.freepik.com/premium-photo/modern-escalators_323624-188.jpg?w=1060", title: "long escalator", link: "long-escalator" },
        { image: "https://img.freepik.com/premium-photo/mechanical-marvel-detail-shot-reveals-escalator-urban-building-subway-station_892776-1984.jpg?w=900", title: "Outdoor Escalators", link: "outdoor-escalators" },
        { image: "https://img.freepik.com/premium-photo/modern-luxury-escalators-with-staircase_49071-941.jpg?w=900", title: "Modern luxury escalators", link: "passenger-elevators-autodoor" },
        { image: "https://img.freepik.com/free-photo/low-angle-shot-escalator-going-up-metro-station-vijzelgracht-netherlands_181624-4984.jpg?t=st=1713350524~exp=1713354124~hmac=2e8c6dd4a4b62a92987066f26908bef6a75f978c5cfa2bb4683e41e387a03a19&w=900", title: "Escalator & Moving Walkway", link: "escalators-interior" },

    ]

    useEffect(() => {
        const updateHeight = () => {
            if (elementRef.current) {
                setHeight(elementRef.current.clientHeight);
            }
        };

        // Update height on mount
        updateHeight();

        // Listen for window resize
        window.addEventListener('resize', updateHeight);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []); // Empty dependency array to run only on mount



    const toggleVisibility = () => {
        if (window.pageYOffset > height) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [height]);




    //     if (locationpathname == "/elevators") {
    //         return (


    //             <div>


    //                 <Container fluid className='mb-5'>
    //                     <Row >

    //                         <Col lg={11} >

    //                             <Row>
    //                                 {/* <h3 className='mt-5 mb-3 elevator-heading'>Elevator</h3> */}
    //                                 <div className='text-center mt-5 mb-3  section-title'>


    //                                     <p >Passenger Elevators</p>
    //                                 </div>

    //                                 <Container className='mb-5'>
    //                                     <Row>
    //                                         <Col lg={12}>
    //                                             <p >Designed for comfort and efficiency, perfect for residential and commercial buildings.</p>
    //                                             <h5 >Experience Comfort and Efficiency with Hyundai Passenger and Elevators</h5>
    //                                             <div >
    //                                                 <p  >At Techno Elevators LLC, we offer a wide range of Hyundai passenger elevators designed to provide a seamless and comfortable vertical transportation experience. Our elevators are engineered with advanced technology to ensure safety, reliability, and efficiency, making them ideal for both residential and commercial buildings.</p>
    //                                             </div>


    //                                             <h4 className='text-center mt-4 mb-4'>Why Choose Hyundai Passenger Elevators?</h4>

    //                                             <h5 >Advanced Technology</h5>
    //                                             <div >
    //                                                 <p  >Hyundai passenger elevators are equipped with cutting-edge technology that ensures smooth and quiet operation, enhancing the overall user experience.
    //                                                 </p>


    //                                             </div>

    //                                             <h5 >Safety First</h5>
    //                                             <div >
    //                                                 <p  >Our elevators are designed with multiple safety features, including emergency braking systems, overload sensors, and automatic rescue devices, to ensure the safety of all passengers.

    //                                                 </p>


    //                                             </div>

    //                                             <h5 >Energy Efficiency</h5>
    //                                             <div >
    //                                                 <p  >Hyundai elevators are built with energy-efficient components that reduce power consumption and lower operational costs, contributing to a greener environment.

    //                                                 </p>


    //                                             </div>
    //                                             <h5 >Customizable Designs</h5>
    //                                             <div >
    //                                                 <p  >
    //                                                     We offer a variety of design options to match the aesthetic of your building. From sleek and modern to classic and elegant, our elevators can be customized to fit your style.

    //                                                 </p>


    //                                             </div>
    //                                             <h5 >Durability and Reliability</h5>
    //                                             <div >
    //                                                 <p  >
    //                                                     Built with high-quality materials and robust engineering, Hyundai passenger elevators are known for their durability and long-lasting performance.

    //                                                 </p>


    //                                             </div>

    //                                             <h4 className='text-center mt-4 mb-4'>Key Features</h4>
    //                                             {keypoints.map((item) => {
    //                                                 return(
    //                                                     <>
    //  <h5 >{item.heading}</h5>
    //  <div style={{display:"flex",justifyContent:'center'}}>
    //  <p style={{width:'80%'}} >  {item.text}</p>


    //  </div></>
    //                                                 )
    //                                             })}

    //                                             <h4  className='text-center mt-4 mb-4'>Applications</h4>
    //                                             <p >Hyundai passenger elevators are suitable for a variety of applications, including:</p>




    //                                         </Col>
    //                                     </Row>
    //                                 </Container>



    //                                 {
    //                                     products.map((item) => {
    //                                         return (
    //                                             <Col lg={3} className='products-col-4 ' data-aos="zoom-in-up">
    //                                                 <Card className='card-container' as={Link} to={item.link}>

    //                                                     <img src={item.image} />
    //                                                     <div className='mt-2'>
    //                                                         <p className='card-title-elevator'>{item.title}</p>

    //                                                         <h6>Read More</h6>
    //                                                     </div>
    //                                                 </Card>

    //                                             </Col>
    //                                         )
    //                                     })
    //                                 }
    //                             </Row>
    //                         </Col>
    //                     </Row>
    //                 </Container>
    //                 <Footer />

    //             </div>
    //         )
    //     }




    //     if (locationpathname == "/escalators") {
    //         return (


    //             <div>


    //                 <Container fluid className='mb-5'>
    //                     <Row >

    //                         <Col lg={11} >

    //                             <Row>
    //                                 {/* <h3 className='mt-5 mb-3 elevator-heading'>Elevator</h3> */}
    //                                 <div className='text-center mt-5 mb-3  section-title'>


    //                                     <p >Escalator</p>
    //                                 </div>

    //                                 <p className='mt-3 mb-4 text-center'>Techno Escalator offers innovative Escalator solutions for vertical transportation needs, blending cutting-edge technology with reliable performance for seamless mobility, Techno Elevators provides a comprehensive range of products tailored to enhance safety, comfort, and efficiency in any building environment.</p>
    //                                 {
    //                                     products2.map((item) => {
    //                                         return (
    //                                             <Col lg={3} className='products-col-4 ' data-aos="zoom-in-up">
    //                                                 <Card className='card-container' as={Link} to={item.link}>

    //                                                     <img src={item.image} />
    //                                                     <div className='mt-2'>
    //                                                         <p className='card-title-elevator'>{item.title}</p>

    //                                                         <h6>Read More</h6>
    //                                                     </div>
    //                                                 </Card>

    //                                             </Col>
    //                                         )
    //                                     })
    //                                 }
    //                             </Row>
    //                         </Col>
    //                     </Row>
    //                 </Container>
    //                 <Footer />

    //             </div>
    //         )
    //     }

    //     if (locationpathname == "/travelators") {
    //         return (


    //             <div>


    //                 <Container fluid className='mb-5'>
    //                     <Row >

    //                         <Col lg={11} >

    //                             <Row>
    //                                 {/* <h3 className='mt-5 mb-3 elevator-heading'>Elevator</h3> */}
    //                                 <div className='text-center mt-5 mb-3  section-title'>


    //                                     <p > Travelators</p>
    //                                 </div>

    //                                 <p className='mt-3 mb-4 text-center'>Techno Escalator offers innovative Escalator solutions for vertical transportation needs, blending cutting-edge technology with reliable performance for seamless mobility, Techno Elevators provides a comprehensive range of products tailored to enhance safety, comfort, and efficiency in any building environment.</p>
    //                                 {
    //                                     products2.map((item) => {
    //                                         return (
    //                                             <Col lg={3} className='products-col-4 ' data-aos="zoom-in-up">
    //                                                 <Card className='card-container' as={Link} to={item.link}>

    //                                                     <img src={item.image} />
    //                                                     <div className='mt-2'>
    //                                                         <p className='card-title-elevator'>{item.title}</p>

    //                                                         <h6>Read More</h6>
    //                                                     </div>
    //                                                 </Card>

    //                                             </Col>
    //                                         )
    //                                     })
    //                                 }
    //                             </Row>
    //                         </Col>
    //                     </Row>
    //                 </Container>
    //                 <Footer />

    //             </div>
    //         )
    //     }


    return (
        <>
            <Container fluid className='mt-5 mb-5'  >
                <Tab.Container id="left-tabs-example" className="position-left-fix" defaultActiveKey="first">
                    <div ref={elementRef}>
                        <Row className='main-row'   >
                            <Col sm={3} style={{ paddingRight: "0px", display: 'flex', justifyContent: 'center' }}>
                                <div className={isVisible ? 'position-left-fix-div-scroll' : 'position-left-fix-div'}>
                                    <div className='text-center  section-title '><p >Elevators</p></div>

                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item className="mt-3">
                                            <Nav.Link eventKey="first">Passenger Elevators</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mt-3 mb-3">
                                            <Nav.Link eventKey="second">Home Elevators</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Freight Elevators</Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </div>
                            </Col>
                            <Col sm={5} style={{ paddingLeft: "0px" }} className='mt-3'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">

                                        <div>
                                            <h5 style={{ fontSize: '18px' }}>Designed for comfort and efficiency, perfect for residential and commercial buildings.</h5>
                                            <h4 style={{ fontSize: '18px' }}>Experience Comfort and Efficiency with Hyundai Passenger and Elevators</h4>
                                            <p>At Techno Elevators LLC, we offer a wide range of Hyundai passenger elevators designed to provide a seamless and comfortable vertical transportation experience. Our elevators are engineered with advanced technology to ensure safety, reliability, and efficiency, making them ideal for both residential and commercial buildings.</p>


                                            <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Why Choose Hyundai Passenger Elevators?</h4>
                                            {elevatorBenefits.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div className={item.classname} >
                                                            <p  >  {item.text}</p>

                                                        </div></>
                                                )
                                            })}

                                            <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                                            {keypoints.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div className={item.classname}  >
                                                            <p>{item.text}</p>
                                                        </div></>
                                                )
                                            })}

                                            <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Applications</h4>

                                            {elevatorApplications.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div className={item.classname}  >
                                                            <p>{item.text}</p>
                                                        </div></>
                                                )
                                            })}
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">

                                        <div>
                                            <h4 style={{ fontSize: '18px' }}>Elevate Your Home with Hyundai Home Elevators</h4>
                                            <p>At Techno Elevators LLC, we offer a range of Hyundai home elevators designed to enhance the convenience, accessibility, and value of your home. Our home elevators combine cutting-edge technology with elegant design, providing a seamless and luxurious vertical transportation solution for your residence.</p>
                                            <p>Stylish and compact elevators that add convenience and value to your home. Our home elevators are designed to seamlessly integrate into your living space, offering a blend of luxury and practicality. Whether you are looking to enhance accessibility or add a touch of sophistication to your home, our Hyundai home elevators are the perfect solution.</p>
                                            {homeElevatorFeatures.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div className={item.classname} >
                                                            <p  >  {item.text}</p>

                                                        </div></>
                                                )
                                            })}




                                            <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                                            {keyFeatures1.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div className={item.classname}  >
                                                            <p  >  {item.text}</p>


                                                        </div></>
                                                )
                                            })}

                                            <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Applications</h4>

                                            {applications.map((item) => {
                                                return (
                                                    <>
                                                        <h5 className="about-sideheading" >{item.heading}</h5>
                                                        <div >
                                                            <p  >  {item.text}</p>


                                                        </div></>
                                                )
                                            })}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">

                                        <h5 >Freight Elevators</h5>
                                        <div >
                                            <p  >Robust and reliable solutions for transporting goods in industrial and commercial settings.</p>


                                        </div>

                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                            <Col lg={4} className={isVisible ? "position-right-fix-scroll mt-3" : "position-right-fix mt-3"}>
                                <img src={'/Images/sliders/side-glass-escalator.jpg'} width="100%" />
                            </Col>
                        </Row>
                    </div>
                </Tab.Container>
                <Container fluid className='mt-5'>
                    <Row>
                        <Col lg={12}>
                            <Row>
                                {
                                products.map((item) => {
                                        return (
                                            <Col lg={3} className='products-col-4 ' data-aos="zoom-in-up">
                                                <Card className='card-container' as={Link} to={item.link}>
                                                    <img src={item.image} />
                                                    <div className='mt-2'>
                                                        <p className='card-title-elevator'>{item.title}</p>
                                                        <h6>Read More</h6>
                                                    </div>
                                                </Card>

                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid style={{ padding: '0px' }}>
                <Footer />

            </Container>
        </>
    )
}

export default Products


